/* main css v1.5.1 */


@import 'cores/mixin';

// Color
$primary      : #1357FF;
$secondary    : #647282;
$heading      : #212121;
$text         : #647282;
$text-light   : #999999;
$border       : #C4CDD4;

$red          : #dc3545;
$orange       : #FF4D1A;


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/custom-plugins';
@import 'components/general';
@import 'components/form';
@import 'components/menu';
@import 'components/footer';
@import 'components/content';
