/* ------------------------ */
/*      CUSTOM PLUGINS      */
/* ------------------------ */

.owl-carousel {
  position: relative;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .owl-stage-outer {
    .owl-stage {
      .owl-item {
        position: relative;
        &> [class*="col-"] {
          padding: 0 !important; }
        .item {
          width: 100% !important;
          max-width: 100% !important;
          margin: 0 !important; } } } }
  .owl-nav {
    position: absolute;
    top: calc(50% - 15px);
    width: 100%;
    .owl-prev,
    .owl-next {
      position: absolute;
      font-size: 1.75rem;
      .fas {
        display: block;
        line-height: 35px;
        text-align: center;
        width: 25px;
        height: 35px;
        background-color: #FFFFFF; } }
    .owl-prev {
      left: 0;
      text-align: left;
      .fas {
        padding-right: 5px;
        border-radius: 0 3px 3px 0; } }
    .owl-next {
      text-align: right;
      right: 0;
      .fas {
        padding-left: 5px;
        border-radius: 3px 0 0 3px; } } }


  .owl-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 30px;
    line-height: 10px;
    .owl-dot {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
      width: 10px;
      span {
        display: block;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background-color: #888; }
      &:focus {
        outline: none !important; }
      &.active {
        span {
          opacity: 1;
          background: $primary; } } } } }


.datepicker {
  table {
    tr {
      td, th {
        border-radius: 2px; }
      td.active.active,
      td.active.highlighted.active,
      td.active.highlighted:active,
      td.active:active,
      td.active.active.focus,
      td.active.active:focus,
      td.active.active:hover,
      td.active.highlighted.active.focus,
      td.active.highlighted.active:focus,
      td.active.highlighted.active:hover,
      td.active.highlighted:active.focus,
      td.active.highlighted:active:focus,
      td.active.highlighted:active:hover,
      td.active:active.focus,
      td.active:active:focus,
      td.active:active:hover {
        background: $primary;
        border: 0; }
      td {
        span.active.active,
        span.active.disabled.active,
        span.active.disabled:active,
        span.active.disabled:hover.active,
        span.active.disabled:hover:active,
        span.active:active,
        span.active:hover.active,
        span.active:hover:active,
        span.active.active.focus,
        span.active.active:focus,
        span.active.active:hover,
        span.active.disabled.active.focus,
        span.active.disabled.active:focus,
        span.active.disabled.active:hover,
        span.active.disabled:active.focus,
        span.active.disabled:active:focus,
        span.active.disabled:active:hover,
        span.active.disabled:hover.active.focus,
        span.active.disabled:hover.active:focus,
        span.active.disabled:hover.active:hover,
        span.active.disabled:hover:active.focus,
        span.active.disabled:hover:active:focus,
        span.active.disabled:hover:active:hover,
        span.active:active.focus,
        span.active:active:focus,
        span.active:active:hover,
        span.active:hover.active.focus,
        span.active:hover.active:focus,
        span.active:hover.active:hover,
        span.active:hover:active.focus,
        span.active:hover:active:focus,
        span.active:hover:active:hover {
          background: $primary;
          border: 0; } }
      td.new,
      td.old {
        color: $border; }
      td.today {
        background: transparent;
        border: 0 !important;
        position: relative;
        &:hover {
          background: #eee !important; }
        &.active:hover {
          background: $primary !important; }
        &:after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 3px;
          background: $primary;
          position: absolute;
          bottom: 2px;
          right: 2px; } } } } }

.datepicker.dropdown-menu {
  z-index: 1100 !important;
  border: 0;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.3), 0 4px 8px -2px rgba(9, 30, 66, 0.25); }
