/* -------------- */
/*      BASE      */
/* -------------- */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

hr {
  border-top: 1px solid $border; }

.container {
  @media (min-width: 1200px) {
    max-width: 1170px; } }

/* -------------------- */
/*      TYPOGRAPHY      */
/* -------------------- */

a:not(.btn) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  text-decoration: none;
  transition: color .4s;
  &:focus {
    text-decoration: none;
    color: $primary; }
  &:hover {
    color: $primary;
    opacity: 1;
    text-decoration: none; } }

p {
  margin: 0 0 20px;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
  strong,b {
    font-weight: bold; }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; }
  a:hover {
    text-decoration: underline; }
  &:last-child {
    margin-bottom: 0; } }

hr {
  border-color: $border; }

h1, h2, h3, h4 {
  margin: 0 0 20px;
  color: $heading;
  font-weight: bold; }

h1 {
  font-size: 48px;
  line-height: 58px;
  @include mobile {
    font-size: 36px;
    line-height: 48px; } }
h2 {
  font-size: 30px;
  line-height: 36px;
  @include mobile {
    font-size: 28px;
    line-height: 42px; } }
h3, h4 {
  font-size: 18px;
  line-height: 26px; }

.lead p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px; }


article {
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: underline; } }
  blockquote {
    font-style: italic; }

  li {
    ul, ol {
      margin: 0 1.5em; } }
  ul, ol {
    margin: 0 0 30px;
    padding-left: 25px;
    &:last-child {
      margin: 0; } }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } } }

.text-center {
  text-align: center; }
.text-right {
  text-align: right; }
.text-left {
  text-align: left; }

.text-bold {
  font-weight: 800; }

.text-semibold {
  font-weight: 600; }

.text-regular {
  font-weight: 400; }

.text-light {
  font-weight: 300; }

.text-capital {
  text-transform: uppercase; }


html, body {
  font-size: 16px;
  line-height: 24px;
  font-family: "Proximanova", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  // +mobile
 }  //   font-size: 14px

body {
  background-color: #FFF; }

