

/* ---------------- */
/*     CONTENT      */
/* ---------------- */

/* LOGIN */
.login-wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #F7F9FA; }

.login-box {
  width: 370px; }
.login-logo {
  margin-bottom: 60px;
  img {
    width: 330px; } }
.login-form {
  .form-action {
    margin-top: 40px;
    border: 0;
    padding: 0;
    justify-content: flex-end; } }


/* TABLE */
table.table {
  margin-bottom: 30px;
  .dropdown-action {
    font-size: 20px;
    cursor: pointer;
    opacity: 1;
    transition: opacity .5s;
    &:hover {
      opacity: .5; } }

  td, th {
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    vertical-align: top;
    border: 0;
    border-bottom: 1px solid #E2E4E6;
    .badge {
      &:not(:first-child) {
        margin-left: 10px; }
      &:not(:last-child) {
        margin-right: 10px; }
      &:not(:first-child):not(:first-child) {
        margin-left: 10px;
        margin-right: 10px; } }
    &:first-child {
      padding-left: 0; }
    &:last-child {
      padding-right: 0; } }

  th {
    color: $heading;
    font-weight: 600;
    vertical-align: bottom;
    .fas,
    .far,
    .fal {
      color: #C4CDD4;
      font-size: 12px;
      line-height: 24px;
      margin-left: 10px;
      display: inline-block;
      &:hover {
        color: $text; } } } }

.table-footer {
  display: flex; }

ul.pagination {
  margin-left: auto;
  &> li {
    display: block;
    padding: 0 10px;
    font-size: 16px;
    line-height: 20px;
    &:first-child,
    &:last-child {
      font-size: 12px;
      a,
      span {
        position: relative;
        top: 1px; } }
    &.disabled {
      opacity: .5; }
    &.active {
      color: $heading;
      font-weight: 600; } } }

.table-vscroll {
  max-height: 495px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); }
  &::-webkit-scrollbar-thumb {
    background-color: #4A5160;
    outline: 1px solid none;
    border-radius: 2.5px; } }

// DETAIL CONTENT

.title-content {
  color: $orange;
  font-size: 21px;
  line-height: 30px;
  font-weight: 600; }
.detail-wrap {
  .title {
    color: $orange;
    font-size: 21px;
    line-height: 30px;
    font-weight: 600; }
  .info-wrap {
    label {
      font-weight: normal;
      margin-bottom: 10px; }
    span {
      display: block;
      margin-bottom: 10px; } } }


.container-import {
  .item-card-content {
    padding-right: 25%; } }


// card
.item-card {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  .item-card-title {
    margin-bottom: 10px;
    span {
      display: block;
      margin: 0;
      font-size: inherit;
      line-height: inherit; }
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 0; } }
  .item-card-content {
    padding-bottom: 30px;
    p {
      font-size: inherit;
      line-height: inherit; } }

  .item-card-img {
    padding-bottom: 30px;
    position: relative;
    align-items: flex-start;
    img {
      display: inline-block;
      height: 70px;
      width: auto;
      max-width: 100%; } }

  .item-card-body {
    position: relative;
    flex: 1 1 auto; } }



.sales-single-wrap {
  position: relative; }

.sales-pipeline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .pipeline-title {
    color: $heading;
    padding-right: 60px;
    @include responsive(1600px) {
      width: 100%;
      margin-bottom: 15px; } }
  .pipeline-bar {
    display: flex;
    margin-left: -10px;
    margin-right: -10px; }

  .pipeline-item {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    flex-grow: 1;
    position: relative;
    .pipeline-item-title {
      font-size: 14px;
      line-height: 20px;
      flex: 1 1 auto; }
    .pipeline-item-check {
      position: relative;
      width: 100%;
      span {
        display: block;
        margin: 10px auto;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid $border;
        position: relative;
        background: #FFF;
        &:before {
          content: "\f00c";
          font-family: 'Font Awesome 5 Pro';
          display: block;
          font-size: 19px;
          top: 2px;
          left: 4px;
          position: absolute;
          color: #FFF; } }
      &.checked span {
        background: $primary;
        border-color: $primary; }
      &:after {
        content: "";
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        background: $orange;
        position: absolute;
        top: 25px;
        left: calc(50% + 25px); } }
    &:last-child .pipeline-item-check:after {
      display: none; } } }


.contact-info-wrap {
  font-size: 14px;
  line-height: 20px;
  .name {
    font-weight: normal;
    display: block;
    margin: 0;
    font-size: 16px;
    line-height: 24px; }

  .item {
    display: block; }

  .view-more {
    color: $primary;
    display: block;
    margin-top: 15px;
    &:hover {
      text-decoration: underline; } } }


.comments-wrap {
  padding-top: 30px;
  .item {
    display: flex;
    margin-bottom: 30px;
    position: relative;
    .edit {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      right: 0;
      display: block;
      opacity: 1;
      transition: all .5s;
      img {
        width: 16px;
        height: 16px; }
      &:hover {
        opacity: .5; } }
    .box-img {
      padding-right: 20px;
      .img {
        border-radius: 15px;
        width: 30px;
        height: 30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }
    .content {
      position: relative;
      width: 100%;
      label {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0; }
      .name {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0; }
      .time {
        font-size: 12px;
        line-height: 14px;
        display: block; }
      &> .text {
        padding-top: 20px;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        p {
          font-size: 14px;
          line-height: 20px; } }
      .due-date {
        line-height: 20px;
        padding-top: 5px;
        .icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat; } } }
    &.comment-editable,
    &.reminder-editable {
      .comment-box {
        padding-top: 15px;
        display: none;
        .action {
          padding: 10px 0 0;
          .btn {
            line-height: 34px; } } }
      &.editing {
        .btn-edit,
        .content > .text {
          display: none; }
        .comment-box {
          display: block; } } } } }


.activity-wrap {
  .item {
    padding-left: 50px;
    position: relative;
    margin-bottom: 30px;
    .img {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0; }

    .name {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0; }
    .time {
      font-size: 12px;
      line-height: 14px;
      display: block; } } }



.forecast-wrap {
  // margin-bottom: 20px
  padding: 5px 0 30px;
  background: #FFF;
  // position: -webkit-sticky
  // position: sticky
  // top: 0
  z-index: 20 !important;
  .forecast-bar {
    width: 100%;
    display: flex;
    min-height: 30px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f2f2f2;
    .bar {
      max-width: 100%;
      min-height: 30px;
      color: #FFF;
      padding: 8px;
      min-width: 200px;
      label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #FFF;
        display: block;
        margin: 0; }
      span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        b {
          font-weight: 600;
          display: inline-block;
          margin-right: 8px; } }

      &.bar-paid {
        background-color: #9785C2; }
      &.bar-sent {
        background-color: #F7969C; }
      &.bar-decline {
        background-color: #999999; } } } }



.card-wrap {
  border-bottom: 1px solid #E9ECED;
  border-left: 1px solid #E9ECED;
  background-color: #EFF3F5;
  overflow: hidden;
  .row {
    margin: 0;
    [class*=col] {
      padding: 0; } }

  .card-wrap-head {
    padding: 10px;
    background-color: #EFF3F5;
    border-right: 1px solid #E9ECED;
    border-top: 1px solid #E9ECED;
    display: flex;
    justify-content: space-between;
    z-index: 20 !important;
    label {
      margin: 0;
      font-weight: 600; }
    .total {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      color: #647282; } }

  .card-wrap-body {
    padding: 5px 5px 0;
    background-color: #EFF3F5;
    border-right: 1px solid #E9ECED;
    border-top: 1px solid #E9ECED;
    position: relative; }

  .card-list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    display: block;
    min-height: 300px;
    @include responsive(0, 1600px) {
      min-height: 600px; }
    .card-item {
      width: 100%;
      padding: 10px;
      background: #FFF;
      border: 1px solid #E9ECED;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      transform: rotate(0);
      transition: background .5s ease, transform .1s linear;
      &> a {
        display: block; }
      &:hover {
        background: #f7f7f7;
        &> a {
          color: inherit; } }
      &.ui-sortable-helper {
        transform: rotate(5deg);
        box-shadow: 5px 5px 13px 3px rgba(0,0,0,0.13); }
      @include responsive(1600px, 0) {
        font-size: 12px;
        line-height: 18px; }
      label {
        font-weight: 500;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        @include responsive(1600px, 0) {
          font-size: 14px;
          line-height: 20px; } }
      .card-body {
        padding: 0;
        span,
        label {
          display: block; } }
      .card-foot {
        padding: 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span.date {
          font-size: 14px;
          line-height: 24px;
          &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            background: url(../images/icon-calendar.png);
            background-size: contain;
            margin-right: 8px;
            position: relative;
            top: -1px; }
          @include responsive(1600px, 0) {
            font-size: 12px;
            line-height: 18px; } }
        span.name {
          display: inline-block;
          // max-width: 100px
          max-width: 60%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        span.img {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          @include responsive(1600px, 0) {
            width: 20px;
            height: 20px; } } } } } }


.list-selected {
  padding: 10px 0;
  ul {
    padding-left: 20px;
    margin-bottom: 0;
    li {
      position: relative; } }
  .cross-btn {
    position: absolute;
    top: 0;
    right: 5px;
    &:after {
      border: 0;
      content: "\f00d";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      margin: 0;
      color: #647282; }
    &:hover {
      cursor: pointer; } } }

.menu-settings {
  padding-left: 0;
  list-style: none;
  li {
    line-height: 50px;
    border-bottom: 1px solid $border;
    padding: 0 15px;
    position: relative;
    margin: 0 0 15px; }
  a {
    display: block; }
  i {
    position: absolute;
    top: calc(50% - 8px);
    right: 15px; } }
