/* ---------------- */
/*       MENU       */
/* ---------------- */

.sidebar {
  display: block;
  width: 240px;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #F7F9FA;
  .sidebar-logo {
    max-width: 100%;
    margin: 0 0 50px;
    img {
      max-width: 100%; } }

  .sidebar-nav {
    &> ul {
      padding: 0;
      margin: 0;
      list-style: none;
      &> li {
        margin: 0 0 15px;
        &> a {
          font-size: 14px;
          line-height: 20px;
          display: block;
          width: 100%;
          padding-left: 35px;
          position: relative;
          span {
            display: inline-block; }
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat; }
          &:hover {
            color: $primary; } }
        &.active > a {
          color: $heading;
          font-weight: 600; } } } }

  .sidebar-profile {
    position: absolute;
    bottom: 0;
    left: 30px;
    display: flex;
    align-items: center;
    padding: 30px 0;
    width: calc(100% - 60px);
    border-top: 1px solid $border;
    .profile-images {
      .img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }
    .profile-info {
      padding-left: 15px;
      span {
        display: block; }
      .profile-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600; }
      .profile-role {
        font-size: 14px;
        line-height: 20px; } } } }



