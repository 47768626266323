/* -------------- */
/*      FORM      */
/* -------------- */

//remove spinner on input type number
input[type=number]::-webkit-inner-spin-button {
  margin-left: 15px; }
input[type=number]:not(.qty)::-webkit-inner-spin-button,
input[type=number]:not(.qty)::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
input[type=number]:not(.qty) {
  -moz-appearance: textfield; }


.form-action {
  border-top: 1px solid $border;
  margin-top: 50px;
  padding: 20px 0;
  text-align: right;
  display: flex;
  align-items: center;
  &.plain {
    margin-top: 0;
    border: 0; }
  .btn {
    min-width: 160px; }
  .archive {
    &> a:hover {
      text-decoration: underline; } }

  .action {
    margin-left: auto; } }

.form-title {
  font-size: 18px;
  line-height: 26px;
  padding-top: 15px;
  margin-bottom: 15px;
  color: $heading;
  &:first-child {
    padding-top: 0; } }

label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  color: $heading;
  span.required {
    color: #F00;
    font-weight: bold; } }


.form-group {
  margin-bottom: 20px; }

.form-control {
  height: 40px;
  border: 1px solid $border;
  border-radius: 2px;
  padding: 6px 12px;
  font-size: 14px;
  color: $heading;
  &:focus,
  &:active:focus {
    border-radius: 3px;
    box-shadow: 0 0 0 1px $primary;
    border-color: $primary; }
  &:disabled,
  &.disabled {
    color: $text-light; } }

.select-control {
  * {
    outline: none !important; }
  &.show > .btn {
    background-color: #FFF !important; }
  &> .btn {
    box-shadow: none;
    font-size: 14px;
    padding: 0 12px;
    color: $heading;
    &:hover,
    &:focus,
    &:active {
      background-color: #FFF !important;
      outline: none !important; }
    &.bs-placeholder {
      color: $text-light; } }
  .dropdown-toggle:after {
    border: 0;
    content: "\f078";
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin: 0;
    color: $text; }
  .dropdown-menu {
    width: auto;
    min-width: 100%; }
  .dropdown-menu > li {
    &.active,
    &:focus,
    &:active {
      &> a.dropdown-item {
        color: $heading !important;
        background-color: #e2e2e2; } }
    &> a.dropdown-item {
      color: $text;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 12px; } }
  .bs-searchbox {
    .form-control {
      height: 32px;
      border-color: $border;
      padding: 0 7px;
      &:focus {
        border-color: $border;
        outline: none;
        box-shadow: none; } } }
  .bs-actionsbox {
    .actions-btn {
      border: 1px solid $border;
      box-shadow: none !important; } }
  .no-results {
    padding: 3px 10px;
    font-size: 14px;
    margin: 0; }
  &.sc-small {
    margin-left: 6px;
    position: relative;
    top: -1px;
    .btn {
      font-size: 16px;
      line-height: 24px;
      color: #647282;
      padding: 0;
      border: 0; }
    .dropdown-toggle:after {
      content: "\f0d7";
      font-weight: bold;
      position: relative;
      top: -2px; } } }

.custom-control {
  .custom-control-label {
    font-weight: normal;
    min-height: 16px; } }

.custom-checkbox {
  .custom-control-label::before {
    border-radius: 2px;
    background: #FFF;
    border: 1px solid $border; } }


.label-box {
  border-radius: 15px;
  padding: 20px 20px 15px;
  border: 1px solid $border;
  color: #000;
  span {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    margin-left: 5px;
 }    // &+ span
  // .name, .info, .address
  //   span + span
  //     margin-left: 5px
  .name {
    display: flex; }
  .address {
    margin-bottom: 5px; }
  .contact {
    margin-bottom: 5px;
    span {
      display: block;
      i {
        font-style: normal; } } }

  .lb-qualifier,
  .lb-data-id {
    opacity: 0.5; }

  .lb-data-id {
    font-size: 14px; }
  .lb-full-name {
    font-weight: 600; }
  .lb-address {
    width: 100%; }
  .lb-country {
    width: 100%; }
  .lb-qualifier {} }

.label-print-wrap {
  padding: 2% 0;
  .label-box {
    width: 46%;
    margin: 2%;
    float: left;
    border-color: #000; } }




// ERROR
.form-group.error {
  .form-control {
    border-color: $red;
    &:focus,
    &:active:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 1px $red;
      border-color: $red; } }
  .error {
    // color: #d63031
    color: $red;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
    i.far {
      display: inline-block;
      margin-right: 7px; } } }


.input-group-transparent {
  position: relative;
  input:not(:last-child) {
    padding-right: 60px; }
  .ig-append {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 40px;
    padding: 0 10px; } }


.tag-box {
  margin: 0 -5px;
  width: 100%;
  padding: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 100px);
  .tag {
    display: block;
    background: $text;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 10px;
    border-radius: 2px;
    color: #FFF;
    margin: 0 5px 10px;
    i {
      line-height: 20px;
      margin-left: 20px;
      cursor: pointer;
      opacity: 1;
      transition: opacity .5s ease;
      &:hover {
        opacity: .5; } } } }



.btn-add-participant {
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  transition: color .4s ease;
  i {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: $text;
    font-size: 11px;
    color: #FFF;
    text-align: center;
    line-height: 20px;
    transition: background .4s ease; }
  &:hover {
    color: $primary;
    i {
      background: $primary; } } }



.file-control {
  position: relative;
  width: 170px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0; }

  &.file-images {
    .file-btn {
      position: relative;
      width: 100%;
      padding-top: calc(100% - 2px);
      background-color: #F7F9FA;
      border: 1px dashed #979797;
      cursor: pointer;
      &:after {
        content: "\f030";
        font-family: 'Font Awesome 5 Pro';
        font-size: 55px;
        line-height: 60px;
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        width: 60px;
        height: 60px;
        color: $text; } }

    .file-delete {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 20;
      font-size: 12px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      &:hover {
        background: $red;
        color: #FFF; } }

    .preview-images {
      .thumb {
        position: relative;
        width: 100%;
        padding-top: 100%;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F7F9FA;
          border: 1px solid $border;
          img {
            max-width: 100%;
            max-height: 100%; } } } }

    &.has-file {
      .file-btn {
        // display: none
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: transparent;
        border-color: transparent;
        opacity: 0;
        background: #000;
        transition: opacity .5s ease;
        &:after {
          color: #FFF; }
        &:hover {
          opacity: .4; } }

      .file-delete {
        display: block; } } } }



.input-password {
  position: relative;
  .form-control {
    padding-right: 50px;
    z-index: 1; }
  .btn {
    position: absolute;
    background: transparent;
    right: 0;
    top: 0;
    padding: 0 15px;
    z-index: 2;
    opacity: .7;
    transition: all .5s;
    &:hover {
      opacity: 1; } } }
