/* ------------------------------ */
/* ANIMATION */
/* ------------------------------ */

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

%shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }


.animate.fade-up {
  transform: translateY(0);
  transition: all 1s ease;
  opacity: 1;
  &.animate:not(.animate-in) {
    transform: translateY(100px);
    opacity: 0; } }
