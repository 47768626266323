/* ----------------- */
/*     COMPONENT     */
/* ----------------- */

.btn {
  line-height: 38px;
  padding: 0 20px;
  border-radius: 2px;
  font-size: 16px;
  position: relative;
  -webkit-appearance: none !important;
  &:not(:disabled):not(.disabled) {
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 0 transparent; } }
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 0.7px 0 0 #1357FF;
    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%); } } }
  &.btn-light {
    color: $text;
    background-color: #FFF;
    border-color: $border;
    box-shadow: 0 0.7px 0 0 #C4CDD4;
    &:not(:disabled):not(.disabled) {
      &:hover {
        color: $heading;
        background-color: $border;
        border-color: $border; } } }
  &.btn-link {
    color: $text; }
  .icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle; }
  .f-icon {
    font-size: 20px;
    line-height: 38px;
    display: inline-block;
    margin-right: 10px; }
  span .ir {
    font-size: 14px;
    margin-left: 10px; } }


.alert {
  border-radius: 2px;
  padding: 10px 15px;
  margin: 0 0 15px;
  .close {
    font-size: 14px;
    line-height: 24px;
    outline: none; }
  .icon {
    margin-right: 15px;
    font-size: 14px;
    line-height: 24px; } }
.badge {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 14px;
  .badge-primary {
    background-color: $primary; }
  .badge-secondary {
    background-color: #647282; }
  .badge-danger {
    background-color: $red; } }



.dropdown {
  .dropdown-menu {
    padding: 5px 0;
    border-radius: 2px;
    border: 0;
    box-shadow: 0 0 1px 0 rgba(9,30,66,0.3), 0 4px 8px -2px rgba(9,30,66,0.25);
    .dropdown-item {
      padding: 5px 10px;
      &:hover {
        background-color: #F7F9FA;
        color: $text; } } } }

input.width-dynamic {
  min-width: 80px;
  max-width: 500px; }

/* ----------------- */
/*      LAYOUT       */
/* ----------------- */

.main-container {
  padding-left: 240px; }

.page-head {
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-title {
    position: relative;
    margin-right: auto;
    .title {
      font-size: 21px;
      line-height: 30px;
      margin: 0; }
    .back {
      font-size: 14px;
      line-height: 20px; }
    .title-editable {
      span {
        display: inline-block;
        &:first-of-type {
          margin-right: 6px; }
        &:last-of-type {
          margin-left: 6px; }
        &:first-of-type:last-of-type {
          margin-left: 0;
          margin-right: 0; } }
      .btn-edit {
        font-size: 16px;
        color: #8993A0;
        display: inline-block;
        vertical-align: baseline;
        margin-left: 10px;
        position: relative;
        top: -2px;
        cursor: pointer;
        transition: color .5s ease;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 10%;
          display: block;
          width: 50%;
          height: 1px;
          background-color: #8993A0; }
        &:hover {
          color: $primary;
          &:after {
            background-color: $primary; } } }
      .title-form {
        display: none;
        span {
          line-height: 30px;
          color: #212121; }
        .form-control {
          padding: 0 10px;
          height: 30px; }
        .action {
          padding-left: 10px; }
        .btn {
          font-size: 14px;
          line-height: 27px;
          padding: 0 10px; } }

      &.editing {
        .title {
          display: none; }
        .title-form {
          display: flex; } } } }



  .page-action {
    position: relative;
    .btn {
      min-width: 160px;
      box-shadow: none;
      &+ .btn {
        margin-left: 15px; } } } }

.page-filter {
  padding: 5px 30px;
  display: flex;
  flex-wrap: wrap;
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 150px; }
  .btn {
    min-width: 100px; }
  .btn-light:not(.dropdown-toggle) {
    background: #C4CDD4;
    color: #000;
    &:hover {
      background: darken(#C4CDD4, 5%); } }
  .form-group {
    margin-bottom: 0;
    width: 160px;
    &:last-child {
      width: auto; }
    &.fg-lg {
      width: 180px;
      @include responsive(0,1600px) {
        width: 300px; } } }

  .page-search {
    margin-left: 20px;
    &> input {
      width: 220px; }
    &.ps-sm > input {
      @include responsive(1600px, 0) {
        width: 160px; } } }

  .left-wrap,
  .right-wrap {
    display: flex;
    transition: all .3s; }

  .right-wrap {
    margin-left: auto; }

  .download {
    .btn {
      padding: 0 15px; } }

  .more-filters {
    max-width: 0;
    visibility: hidden;
    transition: all .3s;
    display: flex;
    .form-group {
      width: 160px;
      opacity: 0;
      transition: all .5s; }

    @include responsive(1600px) {
      transition: none; } }

  &.show-more {
    .more-filters {
      max-width: 100%;
      visibility: visible;
      .form-group {
        opacity: 1; } }
    @include responsive(1600px) {
      .left-wrap {
        width: 100%;
        margin-bottom: 15px; } } } }

.page-content {
  padding: 30px; }


.side-menu-wrap {
  nav.side-menu {
    position: webkit-sticky;
    position: sticky;
    top: 30px;
    &> ul {
      padding: 0;
      margin: 0;
      list-style: none;
      &> li {
        &> a {
          display: block;
          margin-bottom: 10px; } } } } }



// MODAL
.modal-backdrop.show {
  opacity: .75; }
.modal {
  .modal-dialog {
    border: 0; }
  .modal-content {
    border: 0;
    border-radius: 2px;
    box-shadow: -5px 0 10px 0 rgba(0,0,0,0.25);
    .modal-title {
      font-size: 21px;
      line-height: 30px; }
    .modal-header {
      padding: 30px 30px 15px;
      border: 0;
      flex-wrap: wrap;
      p {
        display: block;
        width: 100%; } }
    .modal-body {
      padding: 15px 30px 30px;
      &:first-child {
        padding-top: 30px; }
      &:last-child {
        padding-bottom: 30px; }
      &:first-child:last-child {
        padding-top: 30px;
        padding-bottom: 30px; } } }
  .close {
    outline: none; }

  &.modal-right {
    padding-right: 0 !important;
    &.fade .modal-dialog {
      transform: translate(25%, 0); }
    &.show .modal-dialog {
      transform: translate(0, 0); }
    .modal-dialog {
      margin: 0 0 0 auto;
      min-height: 100vh; }
    .modal-content {
      min-height: 100vh;
      border-radius: 0;
      .modal-footer {
        border-top: 1px solid $border;
        padding: 20px 30px;
        .btn {
          min-width: 160px; } } }

    .form-on-modal {
      .form-action {} } } }


.nav-tabs {
  border: 0;
  .nav-link {
    padding: 0 6px 6px;
    border: 0;
    border-bottom: 4px solid #E2E4E6;
    margin-right: 6px;
    min-width: 120px;
    font-size: 14px;
    line-height: 20px;
    opacity: .5;
    text-align: center;
    &.active {
      border-color: $primary;
      font-weight: 600;
      opacity: 1; }
    &:hover {
      color: $heading; } } }

.tab-content {
  padding-top: 30px; }

.page-tab {
  .nav-tabs {
    margin: 0 30px;
    border-bottom: 1px solid #e2e2e2; } }


.page-loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  z-index: 1050;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../images/loading.svg);
    background-repeat: no-repeat;
    background-position: center; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  &> .col,
  &> [class*="col-"] {
    padding-right: 0;
    padding-left: 0; } }

.sm-gutters {
  margin-right: -8px;
  margin-left: -8px;
  &> .col,
  &> [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; } }


// paginations & margins
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px; }
.pt-30 {
  padding-top: 30px; }
.pb-30 {
  padding-bottom: 30px; }

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px; }
.pt-60 {
  padding-top: 60px; }
.pb-60 {
  padding-bottom: 60px; }

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px; }
.pt-90 {
  padding-top: 90px; }
.pb-90 {
  padding-bottom: 90px; }

.py-120 {
  padding-top: 120px;
  padding-bottom: 120px; }
.pt-120 {
  padding-top: 120px; }
.pb-120 {
  padding-bottom: 120px; }

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }
.mt-10 {
  margin-top: 10px !important; }
.mb-10 {
  margin-bottom: 10px !important; }


.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }
.mt-15 {
  margin-top: 15px !important; }
.mb-15 {
  margin-bottom: 15px !important; }

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px; }
.mt-30 {
  margin-top: 30px; }
.mb-30 {
  margin-bottom: 30px; }

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px; }
.mt-60 {
  margin-top: 60px; }
.mb-60 {
  margin-bottom: 60px; }

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px; }
.mt-90 {
  margin-top: 90px; }
.mb-90 {
  margin-bottom: 90px; }

.my-120 {
  margin-top: 120px;
  margin-bottom: 120px; }
.mt-120 {
  margin-top: 120px; }
.mb-120 {
  margin-bottom: 120px; }



a.disabled {
  pointer-events: none;
  color: #888;
  opacity: 0.5;
  &:hover {
    color: #888; } }
